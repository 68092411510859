import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";

const CTA = () => {
  return (
    <>
      {/* <!-- CTA Area --> */}
      <div className="cta-section-1 py-12 pt-lg-20 pb-lg-18 border-bottom border-gray-3 ">
        <Container>
          <Row className="align-items-center ">
            <Col lg="6">
              <div className="section-title cta-text pr-lg-5">
                <h2 className="gr-text-5 mb-7">
                  Put your email list growth on autopilot
                </h2>
                <p className="gr-text-8">
                  Creating a referral program for your list has never been easier with ReferralKit.
                </p>
              </div>
            </Col>
            <Col lg="6" xl="5" className="offset-xl-1">
              <div className="cta-btn d-flex flex-column flex-sm-row justify-content-lg-end mt-5 mt-lg-0">
                <a href="https://app.referralkit.co/signup" className="btn btn-primary with-icon gr-hover-y">
                  Start referring
                  <i className="icon icon-tail-right font-weight-bold"></i>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CTA;
