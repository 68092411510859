import React from "react";
import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/landing/Hero";
import Features from "../sections/landing/Features";
import Content1 from "../sections/landing/Content1";
import Content2 from "../sections/landing/Content2";
import Testimonial from "../sections/landing/Testimonial";
import Pricing from "../sections/landing/Pricing";
import Faq from "../sections/landing/Faq";
import CTA from "../sections/landing/CTA";

const SaasSubscription = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "center",
          isFluid: true,
        }}
        footerConfig={{
          theme: "light",
          style: "style1", //style1, style2
        }}
      >
        <Hero />
        <Features />
        <Content1 />
        <Content2 />
        <Faq />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default SaasSubscription;
