import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";
import {
  Text,
  Box,
  Heading,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  List,
  ListItem,
  ListIcon,
  Stack,
} from "@chakra-ui/react";

const Column = ({ children, ...props }) => {
  return (
    <Stack spacing={2} {...props}>
      {children}
    </Stack>
  );
};

const benefits = [
  "Includes unlimited referrals",
  "Includes unlimited rewards",
  "Includes unlimited team members",
];

const Pricing = () => {
  const [subscribers, setSubscribers] = React.useState(200);

  const price =
    subscribers < 3000
      ? 0
      : subscribers < 5000
      ? 19
      : subscribers < 10000
      ? 29
      : subscribers < 15000
      ? 39
      : subscribers < 20000
      ? 49
      : subscribers < 25000
      ? 59
      : subscribers < 30000
      ? 69
      : subscribers < 35000
      ? 79
      : subscribers < 40000
      ? 89
      : null;

  return (
    <>
      {/* <!-- Pricing Area --> */}
      <div
        className="pricing-section bg-gradient-light bg-blackish-blue pt-12 pb-7 pt-lg-22 pb-lg-17"
        id="pricing"
      >
        <Container>
          <Row className="justify-content-center">
            <Col xl="7" lg="8" md="10">
              <div className="section-title text-center mb-12 mb-lg-16">
                <h2 className="gr-text-4 mb-7 text-white">
                  Pricing &amp; Plans
                </h2>
                <p className="gr-text-10 px-lg-8 gr-color-white-opacity-7 mb-0">
                  We make growing your newsletter affordable. Stop spending
                  outrageous amounts of money on a referral program.
                </p>
                <br />
                <p className="gr-text-8 px-lg-8 mb-0 support-link text-green">
                  * Every plan comes with a 30-day free trial
                </p>
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center">
            <Column spacing="20px" align="center">
              <Column
                p="40px"
                spacing="40px"
                bg="white"
                borderRadius="20px"
                align="center"
              >
                <Column spacing={`${32 + 24}px`} align="center">
                  <Text
                    fontWeight="bold"
                    fontSize="24px"
                    lineHeight="32px"
                    color="#4A4F56"
                  >
                    I have{" "}
                    <Box
                      as="span"
                      color="#4F7EFE"
                      borderBottom="5px solid #4F7EFE;"
                    >
                      {subscribers}
                    </Box>{" "}
                    subscribers
                  </Text>

                  <Slider
                    aria-label="slider-ex-1"
                    defaultValue={subscribers}
                    onChange={setSubscribers}
                    max={40000}
                    maxW="300px"
                    w="300px"
                    position="relative"
                  >
                    <Text
                      position="absolute"
                      top="-24px"
                      color="#4F7EFE"
                      left="0"
                      fontWeight="bold"
                      fontSize="10px"
                      lineHeight="14px"
                    >
                      0
                    </Text>
                    <Text
                      position="absolute"
                      top="-24px"
                      color="#4F7EFE"
                      right="0"
                      fontWeight="bold"
                      fontSize="10px"
                      lineHeight="14px"
                    >
                      40k
                    </Text>
                    <SliderTrack bg="#BCCEFE" h="8px" borderRadius="8px">
                      <SliderFilledTrack
                        bg="#4F7EFE"
                        h="8px"
                        borderRadius="8px"
                      />
                    </SliderTrack>
                    <SliderThumb bg="#4F7EFE" boxSize="16px" />
                  </Slider>
                </Column>

                <List spacing={6} pl={0}>
                  {benefits.map((benefit) => (
                    <ListItem
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <ListIcon
                        as={() => (
                          <div
                            className="count circle-xs bg-green"
                            style={{
                              display: "inline-flex",
                              maxWidth: "20px",
                              minWidth: "20px",
                              minHeight: "20px",
                              maxHeight: "20px",
                            }}
                          >
                            <i
                              className="fas fa-check text-white gr-text-11"
                              style={{ fontSize: "10px" }}
                            ></i>
                          </div>
                        )}
                        color="#1EC26A"
                        size={24}
                      />
                      <Text
                        m="0"
                        ml="1rem"
                        display="inline"
                        fontSize="18px"
                        fontWeight="600"
                        lineHeight="140%"
                      >
                        {benefit}
                      </Text>
                    </ListItem>
                  ))}
                </List>

                {subscribers === 40000 ? (
                  <Text
                    fontWeight="bold"
                    fontSize="36px"
                    lineHeight="36px"
                    color="#4A4F56"
                  >
                    Contact Us
                  </Text>
                ) : (
                  <Stack spacing={2} isInline align="center">
                    <Text
                      fontWeight="bold"
                      fontSize="36px"
                      lineHeight="36px"
                      color="#4A4F56"
                    >
                      ${price}
                    </Text>
                    <Text fontSize="16px" lineHeight="24px" color="#4A4F56">
                      / month
                    </Text>
                  </Stack>
                )}

                <Button
                  href="https://app.referralkit.co/signup"
                  className="gr-hover-y w-100 mt-9 mt-lg-15 gr-text-9"
                >
                  Start referring
                </Button>
              </Column>

              <Text fontSize="12px" lineHeight="16px" color="white">
                * Price automatically adjusts based on the number of your
                subscribers.
              </Text>
            </Column>
          </Row>

          {/* <Row className="justify-content-center">
            <Col lg="4" md="6" sm="8" className="mb-9">
              <div className="pricing-card gr-hover-shadow-1 bg-white border rounded-10 text-center pt-9 pb-6 pr-6 pl-6">
                <span className="badge gr-badge text-primary gr-bg-blue-opacity-1 rounded-pill gr-text-12 text-uppercase">
                  Beginner
                </span>
                <h2 className="price-value gr-text-3 font-weight-bold text-blackish-blue line-spacing-none mb-2 mt-8">
                  $9
                </h2>
                <p className="gr-text-11 mb-7 mb-lg-11 gr-color-blackish-blue-opacity-7">
                  per month
                </p>
                <p className="gr-text-9 px-5 gr-color-blackish-blue-opacity-7 mb-0">
                  For the newsletters just getting started. &lt;1k subscribers.
                </p>
                <Button className="gr-hover-y w-100 mt-9 mt-lg-15 gr-text-9">
                  Start referring
                </Button>
              </div>
            </Col>
            <Col lg="4" md="6" sm="8" className="mb-9">
              <div className="pricing-card gr-hover-shadow-1 bg-white border rounded-10 text-center pt-9 pb-6 pr-6 pl-6">
                <span className="badge gr-badge text-primary gr-bg-blue-opacity-1 rounded-pill gr-text-12 text-uppercase">
                  Growth
                </span>
                <h2 className="price-value gr-text-3 font-weight-bold text-blackish-blue line-spacing-none mb-2 mt-8">
                  $14
                </h2>
                <p className="gr-text-11 mb-7 mb-lg-11 gr-color-blackish-blue-opacity-7">
                  per month
                </p>
                <p className="gr-text-9 px-5 gr-color-blackish-blue-opacity-7 mb-0">
                  For the newsletters that need growth. &lt;2k subscribers. $5
                  for every additonal 1k subscribers.
                </p>
                <Button className="gr-hover-y w-100 mt-9 mt-lg-15 gr-text-9">
                  Start referring
                </Button>
              </div>
            </Col>
            <Col lg="4" md="6" sm="8" className="mb-9">
              <div className="pricing-card gr-hover-shadow-1 bg-white border rounded-10 text-center pt-9 pb-6 pr-6 pl-6">
                <span className="badge gr-badge text-primary gr-bg-blue-opacity-1 rounded-pill gr-text-12 text-uppercase">
                  Enterprise
                </span>
                <h2 className="price-value gr-text-3 font-weight-bold text-blackish-blue line-spacing-none mb-2 mt-8">
                  $99+
                </h2>
                <p className="gr-text-11 mb-7 mb-lg-11 gr-color-blackish-blue-opacity-7">
                  per month
                </p>
                <p className="gr-text-9 px-5 gr-color-blackish-blue-opacity-7 mb-0">
                  For the highly established newsletters. 20k+ subscribers.
                </p>
                <Button className="gr-hover-y w-100 mt-9 mt-lg-15 gr-text-9">
                  Start referring
                </Button>
              </div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
};

export default Pricing;
