import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Faq = () => {
  return (
    <>
      {/* <!-- Faq Area --> */}
      <div className="faq-section pb-12 pt-12 pb-lg-24 pt-lg-19 bg-blackish-blue dark-mode-texts">
        <Container>
          <Row>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    What ESPs do you integrate with?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    We're currently integrating with MailChimp, ConvertKit,
                    MailerLite, and AWeber. On our roadmap we have Drip, Ghost,
                    Campaign Monitor, and Active Campaign.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    Do you fulfil digital and physical rewards?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    When you create a milestone you are able to configure the
                    message that is sent to the subscriber when they reach it.
                    You'll simply add the instructions on how to access their
                    reward. This could be a link or discount code for digital
                    rewards or instructions on where to send their address for
                    physical rewards.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="6" className="mb-9 pr-xl-12 mb-lg-15">
              <div className="single-faq media">
                <div className="count circle-xs bg-green mr-8">
                  <i className="fas fa-question text-white"></i>
                </div>
                <div className="faq-text">
                  <h3 className="faq-title mb-7 gr-text-7 gr-text-color">
                    Will you provide support?
                  </h3>
                  <p className="gr-text-9 gr-text-color-opacity">
                    Yes, our team will provide full support in integrating,
                    setting up rewards, and any questions you have. We're here
                    to help you run a successful referral program.
                  </p>
                </div>
              </div>
            </Col>
            <div className="col-12 text-center mt-3 ">
              <p className="more-support gr-text-9 gr-text-color">
                Haven’t got your answer?{" "}
                <a
                  className="support-link text-green"
                  href="mailto:support@referralkit.co"
                >
                  Contact our support now
                </a>
              </p>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Faq;
